import React, { useContext, useState } from "react";
import { EditAuditPlanning } from "Modules/Customers/Audit/AuditPlanning/EditAuditPlanning";
import { Breadcrumb, Col, Form, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useParams } from "react-router";
import { AuditPlanningTemplatebutton } from "../AuditPlanningTemplate";
import {
  useGetAuditPlan,
  useGetAuditPlansInGroup,
} from "utils/connectors/auditPlanningConnectors";
import DownloadItem from "common/DownloadItem";
import AuditPlanningRelatedItems from "./AuditPlanningRelatedItems";
import { useGetArtifactsInGroup } from "utils/connectors/artifactsConnectors";
import { AttributeDateDisplay } from "common/FormExtras";
import { usePermissions } from "common/Permissions";
import { StickyHeader } from "common/StickyHeader";
import GenerateReport from "utils/docx-export/GenerateReport";
import { auditPlanningReportSchema } from "../auditPlanningReportSchema";
import {
  GetAuditPlanningQuery,
  ModifyAuditPlanningMutation,
  ModifyAuditPlanningMutationVariables,
  ViewType,
} from "API";
import { MutationTuple, useMutation, gql, ApolloError } from "@apollo/client";
import Loader from "common/Loader";
import { modifyAuditPlanning } from "graphql/mutations";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import TitleEdit from "common/TitleEdit";
import { RelatedArtifacts } from "common/RelatedItems";
import { ActivityFeedV2 } from "common/ActivityFeed";
import { useAuditPlanningSchema } from "../auditPlanningSchema";
import AuditPlanningPageAttributes from "./AuditPlanningPageAttributes";
import AuditPlanningPageDetails from "./AuditPlanningPageDetails";
import AuditPlanningPageResults from "./AuditPlanningPageResults";
import AuditPlanningPageScope from "./AuditPlanningPageScope";

export const AuditPlanningContext = React.createContext<{
  auditPlan: Partial<GetAuditPlanningQuery["getAuditPlanning"]>;
  updateAuditPlan:
    | MutationTuple<
        ModifyAuditPlanningMutation,
        ModifyAuditPlanningMutationVariables
      >[0]
    | (() => Promise<void>);
  viewType: string;
}>({
  auditPlan: {},
  updateAuditPlan: () => new Promise<void>((resolve) => resolve()),
  viewType: "",
});

export const useAuditPlanContext = () => useContext(AuditPlanningContext);

export const AuditPlanningPage = () => {
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const history = useHistory();
  const rightSidebarCollapsed = localStorage.getItem("rightsidebar-collapsed");
  const [isExpanded, setIsExpanded] = useState(!rightSidebarCollapsed);
  const { auditPlan, refetch } = useGetAuditPlan(id);
  const { auditPlans, auditPlansTemplates }: any = useGetAuditPlansInGroup();
  const { groupRole, group } = usePermissions();
  const auditPlanSchema = useAuditPlanningSchema();
  const [updateAuditPlan] = useMutation<
    ModifyAuditPlanningMutation,
    ModifyAuditPlanningMutationVariables
  >(gql(modifyAuditPlanning), {
    onCompleted: () => {
      ToastifyQueue("Audit Plan Updated Successfully.", "success");
      refetch();
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  return (
    (!auditPlan && <Loader />) ||
    (auditPlan && (
      <AuditPlanningContext.Provider
        value={{ auditPlan, updateAuditPlan, viewType: auditPlan?.viewType }}
      >
        <StickyHeader>
          <Col xs="12">
            <Breadcrumb>
              <LinkContainer to="/audits" exact>
                <Breadcrumb.Item>Audits</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active={false}>
                {auditPlan?.title}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Row>
            <Col xs="6">
              <TitleEdit
                item={auditPlan}
                refetch={refetch}
                mutation={modifyAuditPlanning}
                userRole={groupRole}
                items={
                  !auditPlan.isTemplate === true
                    ? auditPlans
                    : auditPlansTemplates
                }
                type="audit plan"
              />
              <Nav variant="tabs" style={{ display: "inline-flex" }}>
                <LinkContainer exact to={`/audits/${id}/details`}>
                  <Nav.Link>Details</Nav.Link>
                </LinkContainer>
                <LinkContainer to={`/audits/${id}/relatedItems`}>
                  <Nav.Link>Related Items</Nav.Link>
                </LinkContainer>
              </Nav>
            </Col>
            <Col xs="6">
              <div style={{ marginLeft: "200px" }}>
                {" "}
                <AttributeDateDisplay item={auditPlan} />{" "}
              </div>
            </Col>
          </Row>
        </StickyHeader>
        {tab === "details" && (
          <Row className="mt-205">
            <Col sm="8">
              <Form>
                <Form.Check
                  label="Standard"
                  inline
                  style={{ width: "auto" }}
                  name="group1"
                  type="radio"
                  value="Standard"
                  checked={auditPlan?.viewType === ViewType.Standard}
                  onChange={async (e) =>
                    await updateAuditPlan({
                      variables: {
                        input: [
                          {
                            id,
                            groupID: group.id,
                            viewType: ViewType.Standard,
                          },
                        ],
                      },
                    })
                  }
                />
                <Form.Check
                  label="Advanced"
                  inline
                  style={{ width: "auto" }}
                  name="group1"
                  type="radio"
                  value="Advanced"
                  checked={
                    auditPlan?.viewType === ViewType.Advanced ||
                    auditPlan?.viewType === null
                  }
                  onChange={async (e) =>
                    await updateAuditPlan({
                      variables: {
                        input: [
                          {
                            id,
                            groupID: group.id,
                            viewType: ViewType.Advanced,
                          },
                        ],
                      },
                    })
                  }
                />
              </Form>
            </Col>
            <Col sm="4" className="float-right">
              {tab === "details" && (
                <span className="d-block text-right">
                  {groupRole !== "read" && (
                    <AuditPlanningTemplatebutton
                      auditPlan={auditPlan}
                      onComplete={(response) => {
                        const auditPlanTemplate =
                          response?.CloneAuditPlan ||
                          response?.AuditPlanTemplate;
                        const auditPlanTemplateId =
                          (auditPlanTemplate?.[0] &&
                            JSON.parse(auditPlanTemplate?.[0])?.id) ??
                          null;
                        if (auditPlanTemplateId) {
                          history.push(`/audits/${auditPlanTemplateId}`);
                        } else {
                          refetch();
                        }
                      }}
                    />
                  )}
                  <GenerateReport
                    type="Single"
                    item={auditPlan}
                    schema={auditPlanningReportSchema()}
                  />
                </span>
              )}
            </Col>
            <Col sm={12} xl={isExpanded ? 12 : 8}>
              <AuditPlanningPageAttributes />
              <AuditPlanningPageDetails />
              <AuditPlanningPageScope />
              <AuditPlanningPageResults />
            </Col>
            <div className="aside">
              <RelatedArtifacts
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                refetchItem={refetch}
              />
            </div>
          </Row>
        )}
        {tab === "relatedItems" && <AuditPlanningRelatedItems />}
      </AuditPlanningContext.Provider>
    )) ||
    "You don't have permission to view this item"
  );
};
